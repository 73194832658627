
























































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import {IApiTalent} from '@/shared/models/IApiTalent';
import RsButton from '@/shared/components/buttons/button.vue';
import OnboardingModule from '@/modules/onboarding'
import {Watch} from 'vue-property-decorator';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import {API_SERVICE} from '@/shared/api/api.service';
import {
  CodetableResult,
  Codetables,
} from '@/shared/api/modules/codetable.api.service';
import {DeveloperRoleInfo, DeveloperRoleType, rolesInfo} from '@/shared/constants/ROLES';
import RsInput from '@/shared/components/fields/input.vue';
import RsSelect from '@/shared/components/fields/select.vue';
import RsCheckbox from '@/shared/components/fields/checkbox.vue';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {Icons} from '@/shared/components/icon/icons';

interface FormPreferences {
  preferedRoles: string[],
  interestStatus: string[];
  salaryFrom: string;
  salaryTo: string;
}

@Component({
  name: 'rs-form-profile-edit-preferences',
  components: {RsCheckbox, RsSelect, RsInput, RsButton, RsIcon, RsCheckboxCard},

  validations() {
    const self: any = this;
    return {
      form: {
        interestStatus: {
          minimumSelection(val) {
            if (self.user.properties.TALENT_INTEREST_STATUS) {
              return val && val.length > 0;
            }
            return true
          }
        },
        salaryFrom: {
          toExists(val: string) {
            const salaryTo = self.form.salaryTo && self.form.salaryTo.length > 0
              ? Number(self.form.salaryTo.replace(/\D/g, '')) : self.form.salaryTo;
            const value = val && val.length > 0 ? Number(val.replace(/\D/g, '')) : val;
            return !value || !(!!salaryTo && !value) && !(!value && !!self.user.properties.COMPENSATION)
          },
          minValue(val: string) {
            const value = val && val.length > 0 ? Number(((val) as string).replace(/\D/g, '')) : val;
            return !value || value >= 20000;
          },
          maxValue(val: string) {
            const value = val && val.length > 0 ? Number(((val) as string).replace(/\D/g, '')) : val;
            return !value || value <= 500000;
          },
        },
        salaryTo: {
          fromExists(val: string) {
            const salaryFrom = self.form.salaryFrom && self.form.salaryFrom.length > 0
              ? Number(self.form.salaryFrom.replace(/\D/g, '')) : self.form.salaryFrom;
            const value = val && val.length > 0 ? Number(val.replace(/\D/g, '')) : val;
            return !value || !(!!salaryFrom && !value) && !(!value && !!self.user.properties.COMPENSATION)
          },
          moreThanFrom(val: string) {
            const salaryFrom = self.form.salaryFrom && self.form.salaryFrom.length > 0
              ? Number(((self.form.salaryFrom + '') as string).replace(/\D/g, '')) : self.form.salaryFrom;
            const value = val && val.length > 0 ? Number(((val) as string).replace(/\D/g, '')) : val;
            return !value || !salaryFrom || (!!salaryFrom && !!value) && (Number(value) > Number(salaryFrom))
          },
          minValue(val: string) {
            const value = val && val.length > 0 ? Number(((val) as string).replace(/\D/g, '')) : val;
            return !value || value >= 20000;
          },
          maxValue(val: string) {
            const value = val && val.length > 0 ? Number(((val) as string).replace(/\D/g, '')) : val;
            return !value || value <= 500000;
          },
          maxDifference(val: string) {
            const salaryFrom = self.form.salaryFrom && self.form.salaryFrom.length > 0 ? Number(((self.form.salaryFrom + '') as string).replace(/\D/g, '')) : (self.form.salaryFrom || 0) as number;
            const value = val && val.length > 0 ? Number(((val) as string).replace(/\D/g, '')) : (val || 0) as number;
            return ((value - salaryFrom) as number) <= 100000;
          },
        }
      }
    }
  }
})
export default class FormProfileEditPreferences extends Vue {
  @Action(OnboardingActions.SET_USER, {namespace: OnboardingModule.namespace}) setUser: (user: IApiTalent) => void;
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  Icons = Icons;

  rolesInfo: Record<DeveloperRoleType, DeveloperRoleInfo> = rolesInfo;

  form: FormPreferences = {
    preferedRoles: [],
    interestStatus: [],
    salaryFrom: '',
    salaryTo: ''
  }

  sending = {
    form: false,
  }

  codetables: CodetableResult = {}

  error = '';

  numberMask = ''


  @Watch('user')
  onUserChange(user: IApiTalent) {
    this.fillForm(user);
  }

  submit() {
    this.error = '';
    this.sending.form = true;
    const data: IApiTalentUpdateRequest = {
      properties: {
        TALENT_INTEREST_STATUS: this.form.interestStatus.length > 0 ?
          (this.form.interestStatus.length > 1 ? 'both' : this.form.interestStatus[0]) : undefined,
        COMPENSATION: (this.form.salaryFrom && this.form.salaryTo) ? {
          CURRENCY: 'USD',
          FROM_VALUE: Number(((this.form.salaryFrom + '') as string).replace(/\D/g, '')),
          TO_VALUE: Number(((this.form.salaryTo + '') as string).replace(/\D/g, '')),
        } : undefined,
        TALENT_PREFERRED_ROLES: this.form.preferedRoles
      },
    }

    API_SERVICE.auth.updateUser(data)
      .then((res) => {
        this.$track.sendEvent('ProfileEdit', {section: 'preferences'})
        this.setUser(res);
        // @ts-ignore
        this.$rs.toast({
          icon: Icons.CHECKMARK_CHECKED,
          title: 'Changes saved',
          body: 'Your profile has been updated'
        })
      })
      .catch((err) => {
        this.error = err.message;
      })
      .finally(() => {
        this.sending.form = false;
      })
  }

  fillForm(user: IApiTalent) {
    if (user.properties.TALENT_INTEREST_STATUS) {
      this.form.interestStatus = user.properties.TALENT_INTEREST_STATUS === 'both' ?
        ['part-time', 'full-time'] : ([user.properties.TALENT_INTEREST_STATUS] || []) as string[];
    }
    if (user.properties.COMPENSATION) {
      this.form.salaryFrom = user.properties.COMPENSATION.FROM_VALUE.toString() || '';
      this.form.salaryTo = user.properties.COMPENSATION.TO_VALUE.toString() || '';
    }
    this.form.preferedRoles = user.properties.TALENT_PREFERRED_ROLES || [];

    this.$nextTick(() => {
      this.numberMask = createNumberMask({
        prefix: ''
      })
    })
  }


  async mounted() {
    this.codetables = await API_SERVICE.codetable.getCodetables(
      Codetables.DEVELOPER_ROLES,
    );
    if (this.user) {
      this.fillForm(this.user);
    }
  }
}
