



































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsInput from '@/shared/components/fields/input.vue';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import {IApiTalent} from '@/shared/models/IApiTalent';
import RsButton from '@/shared/components/buttons/button.vue';
import OnboardingModule from '@/modules/onboarding'
import {Watch} from 'vue-property-decorator';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import {API_SERVICE} from '@/shared/api/api.service';
import {IApiSocialConnection} from '@/shared/models/IApiSocialConnection';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  name: 'rs-form-profile-edit-references',
  components: {RsButton, RsInput},
  validations: {
    form: {
      github: {
        checkregex: (val) => (!(val?.length && !val.match(/(github\.com)/))),
      },
      gitlab: {
        checkregex: (val) => (!(val?.length && !val.match(/(gitlab\.com)/))),
      },
      stackoverflow: {
        checkregex: (val) => (!(val?.length && !val.match(/\bstackoverflow\.com\b/))),
      },
      linkedin: {
        checkregex: (val) => (!(val?.length && !val.match(/\blinkedin\.com\b/))),
      },
      twitter: {
        checkregex: (val) => (!(val?.length && !val.match(/\btwitter\.com\b/))),
      },
    }
  },
})
export default class FormProfileEditReferences extends Vue {
  @Action(OnboardingActions.SET_USER, {namespace: OnboardingModule.namespace}) setUser: (user: IApiTalent) => void;
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  form: Record<string, string> = {
    github: '',
    gitlab: '',
    stackoverflow: '',
    linkedin: '',
    twitter: '',
    website: '',
    other: '',
  }

  sending = {
    file: false,
    form: false,
  }

  error = '';

  @Watch('user')
  onUserChange(user: IApiTalent) {
    this.fillForm(user);
  }

  submit() {
    this.error = '';
    this.sending.form = true;
    if (!this.$v.$invalid) {
      const data: IApiTalentUpdateRequest = {
        socials: this.form
      }
      API_SERVICE.auth.updateUser(data)
        .then((res) => {
          this.$track.sendEvent('ProfileEdit', {section: 'references'})
          this.setUser(res);
          // @ts-ignore
          this.$rs.toast({
            icon: Icons.CHECKMARK_CHECKED,
            title: 'Changes saved',
            body: 'Your profile has been updated'
          })
        })
        .catch((err) => {
          this.error = err.message;
        })
        .finally(() => {
          this.sending.form = false;
        })
    }
  }

  fillForm(user: IApiTalent) {
    user.socialConnections?.forEach((connection: IApiSocialConnection) => {
      this.form[connection.social] = connection.link;
    });
  }


  async mounted() {
    if (this.user) {
      this.fillForm(this.user);
    }
  }
}
