













import Component from 'vue-class-component';
import Vue from 'vue';
import RsFormProfileEditWork from '@/modules/dashboard/modules/profile/components/forms/edit/work.vue';

@Component({
  name: 'rs-profile-edit-work',
  components: {RsFormProfileEditWork}
})
export default class ProfileEditWork extends Vue {}
