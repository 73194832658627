













import Component from 'vue-class-component';
import Vue from 'vue';
import RsFormProfileEditReferences from '@/modules/dashboard/modules/profile/components/forms/edit/references.vue';

@Component({
  name: 'rs-profile-edit-references',
  components: {RsFormProfileEditReferences}
})
export default class ProfileEditReferences extends Vue {}
