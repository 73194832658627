























































































































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';
import OnboardingModule from '@/modules/onboarding'
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {Watch} from 'vue-property-decorator';
import RsButton from '@/shared/components/buttons/button.vue';
import RsRadioCard from '@/shared/components/fields/radio-card.vue';
import RsSelect from '@/shared/components/fields/select.vue';
import RsModal from '@/shared/components/modal/modal.vue';
import RsFormProfileEditWorkExperience, {ExperienceModel}
  from '@/modules/dashboard/modules/profile/components/forms/edit/work/experience.vue';
import RsFormProfileEditWorkEducation, {EducationModel}
  from '@/modules/dashboard/modules/profile/components/forms/edit/work/education.vue';
import RsFormProfileEditWorkProject, {ProjectModel}
  from '@/modules/dashboard/modules/profile/components/forms/edit/work/project.vue';
import RsFormProfileEditWorkCertificate, {CertificateModel}
  from '@/modules/dashboard/modules/profile/components/forms/edit/work/certificate.vue';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import {TALENT_PROJECT_EXPERIENCE_TYPE} from '@/shared/constants/TALENT_PROJECT_EXPERIENCE_TYPE';
import {API_SERVICE} from '@/shared/api/api.service';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';

interface ProfileEditWorkForm {
  experiences: ExperienceModel[];
  education: EducationModel[];
  projects: ProjectModel[];
  certificates: CertificateModel[];
}

@Component({
  name: 'rs-form-profile-edit-work',
  components: {
    RsIcon,
    RsFormProfileEditWorkCertificate,
    RsFormProfileEditWorkProject,
    RsFormProfileEditWorkEducation, RsFormProfileEditWorkExperience, RsModal, RsSelect, RsRadioCard, RsButton
  },
})
export default class FormProfileEditWork extends Vue {
  @Action(OnboardingActions.SET_USER, {namespace: OnboardingModule.namespace}) setUser: (user: IApiTalent) => void;
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  Icons = Icons;

  form: ProfileEditWorkForm = {
    experiences: [],
    education: [],
    projects: [],
    certificates: []
  }

  sending = {
    file: false,
    form: false,
  }

  add: Record<string, boolean> = {
    experience: false,
    education: false,
    projects: false,
    certificates: false,
  }

  edit: Record<string, number | null> = {
    experience: null,
    education: null,
    projects: null,
    certificates: null,
  }

  open: Record<string, boolean> = {
    experience: false,
    education: false,
    projects: false,
    certificates: false,
  }

  error = '';

  @Watch('user')
  onUserChange(user: IApiTalent) {
    this.fillForm(user);
  }

  addExperience(data: ExperienceModel) {
    this.form.experiences.push(data);
    this.add.experience = false;
    this.submit();
  }

  addEducation(data: EducationModel) {
    this.form.education.push(data);
    this.add.education = false;
    this.submit();
  }

  addProject(data: ProjectModel) {
    this.form.projects.push(data);
    this.add.projects = false;
    this.submit();
  }

  addCertificate(data: CertificateModel) {
    this.form.certificates.push(data);
    this.add.certificates = false;
    this.submit();
  }

  editExperience(data: ExperienceModel) {
    this.form.experiences[this.edit.experience as number] = data;
    this.open.experiences = false;
    setTimeout(() => {
      this.edit.experience = null;
    }, 200);
    this.submit();
  }

  editEducation(data: EducationModel) {
    this.form.education[this.edit.education as number] = data;
    this.open.education = false;
    setTimeout(() => {
      this.edit.education = null;
    }, 200);
    this.submit();
  }

  editProject(data: ProjectModel) {
    this.form.projects[this.edit.projects as number] = data;
    this.open.projects = false;
    setTimeout(() => {
      this.edit.projects = null;
    }, 200);
    this.submit();
  }

  editCertificate(data: CertificateModel) {
    this.form.certificates[this.edit.certificates as number] = data;
    this.open.certificates = false;
    setTimeout(() => {
      this.edit.certificates = null;
    }, 200);
    this.submit();
  }

  removeExperience() {
    this.form.experiences.splice(this.edit.experience as number, 1);
    this.open.experiences = false;
    setTimeout(() => {
      this.edit.experience = null;
    }, 200);
    this.submit();
  }

  removeEducation() {
    this.form.education.splice(this.edit.education as number, 1);
    this.open.education = false;
    setTimeout(() => {
      this.edit.education = null;
    }, 200);
    this.submit();
  }

  removeProject() {
    this.form.projects.splice(this.edit.projects as number, 1);
    this.open.projects = false;
    setTimeout(() => {
      this.edit.projects = null;
    }, 200);
    this.submit();
  }

  removeCertificate() {
    this.form.certificates.splice(this.edit.certificates as number, 1);
    this.open.certificates = false;
    setTimeout(() => {
      this.edit.certificates = null;
    }, 200);
    this.submit();
  }

  closeExperience(){
    this.open.experience = false;
    setTimeout(() => {
      this.edit.experience = null;
    }, 200);
  }

  closeEducation(){
    this.open.education = false;
    setTimeout(() => {
      this.edit.education = null;
    }, 200);
  }

  closeProject(){
    this.open.projects = false;
    setTimeout(() => {
      this.edit.projects = null;
    }, 200);
  }

  closeCertificate(){
    this.open.certificates = false;
    setTimeout(() => {
      this.edit.certificates = null;
    }, 200);
  }

  submit() {
    this.error = '';
    this.sending.form = true;
    let index = -1;
    const data: IApiTalentUpdateRequest = {
      projectExperiences: [
        ...(this.form.experiences.map((exp) => {
          index++;
          return {type: TALENT_PROJECT_EXPERIENCE_TYPE.WORK, sortOrder: index, data: exp}
        })),
        ...(this.form.education.map((exp) => {
          index++;
          return {type: TALENT_PROJECT_EXPERIENCE_TYPE.EDUCATION, sortOrder: index, data: exp}
        })),
        ...(this.form.projects.map((exp) => {
          index++;
          return {type: TALENT_PROJECT_EXPERIENCE_TYPE.PROJECT, sortOrder: index, data: exp}
        })),
        ...(this.form.certificates.map((exp) => {
          index++;
          return {type: TALENT_PROJECT_EXPERIENCE_TYPE.CERTIFICATE, sortOrder: index, data: exp}
        }))
      ],
    }
    API_SERVICE.auth.updateUser(data)
      .then((res) => {
        this.$track.sendEvent('ProfileEdit', {section: 'work'})
        this.setUser(res);
        // @ts-ignore
        this.$rs.toast({
          icon: Icons.CHECKMARK_CHECKED,
          title: 'Changes saved',
          body: 'Your profile has been updated'
        })
      })
      .catch((err) => {
        this.error = err.message;
      })
      .finally(() => {
        this.sending.form = false;
      })
  }

  fillForm(user: IApiTalent) {
    this.form.experiences = user.projectExperiences
      .filter((pe: any) => pe.type === TALENT_PROJECT_EXPERIENCE_TYPE.WORK)
      .map((pe: any) => pe.data);
    this.form.education = user.projectExperiences
      .filter((pe: any) => pe.type === TALENT_PROJECT_EXPERIENCE_TYPE.EDUCATION)
      .map((pe: any) => pe.data);
    this.form.projects = user.projectExperiences
      .filter((pe: any) => pe.type === TALENT_PROJECT_EXPERIENCE_TYPE.PROJECT)
      .map((pe: any) => pe.data);
    this.form.certificates = user.projectExperiences
      .filter((pe: any) => pe.type === TALENT_PROJECT_EXPERIENCE_TYPE.CERTIFICATE)
      .map((pe: any) => pe.data);
  }


  async mounted() {
    if (this.user) {
      this.fillForm(this.user);
    }
  }
}
