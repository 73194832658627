












































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {ProfileEditSections, ProfileRouter} from '@/modules/dashboard/modules/profile/router';
import RsProfileEditPersonalInfo from '@/modules/dashboard/modules/profile/views/edit/personal-info.vue';
import RsProfileEditSkills from '@/modules/dashboard/modules/profile/views/edit/skills.vue';
import RsProfileEditWork from '@/modules/dashboard/modules/profile/views/edit/work.vue';
import RsProfileEditReferences from '@/modules/dashboard/modules/profile/views/edit/references.vue';
import {Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {PROFILE_COMPLETION_SERVICE} from '@/modules/dashboard/modules/profile/services/profile-completion.service';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsDashboardJobStatusWidget from '@/modules/dashboard/components/global/job-status/job-status-widget.vue';
import {Icons} from '@/shared/components/icon/icons';
import RsProfileEditPreferences from '@/modules/dashboard/modules/profile/views/edit/preferences.vue';

@Component({
  components: {
    RsProfileEditPreferences,
    RsDashboardJobStatusWidget,
    RsIcon, RsProfileEditReferences, RsProfileEditWork, RsProfileEditSkills, RsProfileEditPersonalInfo}
})
export default class ProfileEdit extends Vue {
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  ProfileRouter = ProfileRouter;
  ProfileEditSections = ProfileEditSections;
  complete = PROFILE_COMPLETION_SERVICE;
  Icons = Icons;

  section: ProfileEditSections = ProfileEditSections.PERSONAL_INFO;

  firstScroll = true;

  @Watch('$route.params.section', {
    immediate: true,
    deep: true
  })
  onSectionChange(section: ProfileEditSections) {
    this.section = section;
    if(!this.firstScroll && window.innerWidth < 992){
      const content = document.querySelector('#profileEditContent');
      const body = document.querySelector('body');
      if(content && body){
        body.scrollTo({
          top: content.getBoundingClientRect().top - 48,
          behavior: 'smooth'
        })
      }
    }
    this.firstScroll = false;
  }

  mounted(){
    this.$track.sendEvent('ProfileEditPage')
  }
}
