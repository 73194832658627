




































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {required} from 'vuelidate/lib/validators';
import {CodetableResult, Codetables} from '@/shared/api/modules/codetable.api.service';
import {API_SERVICE} from '@/shared/api/api.service';
import {debounce} from 'lodash';
import {DATE_SERVICE} from '@/shared/services/date.service';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import {IApiTalent} from '@/shared/models/IApiTalent';
import OnboardingModule from '@/modules/onboarding'
import {Watch} from 'vue-property-decorator';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import RsFile from '@/shared/components/fields/file.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsInput from '@/shared/components/fields/input.vue';
import RsSelect from '@/shared/components/fields/select.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {Icons} from '@/shared/components/icon/icons';

interface FormPersonalInfo {
  profile: string | null;
  firstName: string;
  lastName: string;
  location: string;
  country: string;
  timezone: string;
}

@Component({
  name: 'rs-form-profile-edit-personal-info',
  components: {RsButton, RsSelect, RsInput, RsIcon, RsFile},
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      location: {
        required,
      },
      timezone: {
        required,
      },
    }
  },
})
export default class FormProfileEditPersonalInfo extends Vue {
  @Action(OnboardingActions.SET_USER, {namespace: OnboardingModule.namespace}) setUser: (user: IApiTalent) => void;
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  Icons = Icons;

  form: FormPersonalInfo = {
    profile: null,
    firstName: '',
    lastName: '',
    location: '',
    country: '',
    timezone: '',
  }

  sending = {
    file: false,
    form: false,
  }

  codetables: CodetableResult = {}

  service: any;
  locationSuggestions: any[] = [];
  locationSuggestionsIndex = -1;

  error = '';
  avatarError = '';

  @Watch('user')
  onUserChange(user: IApiTalent){
    this.fillForm(user);
  }

  submit() {
    this.error = '';
    this.sending.form = true;
    if (!this.$v.$invalid) {
      const data: IApiTalentUpdateRequest = {
        properties: {
          TALENT_NAME: this.form.firstName,
          TALENT_LAST_NAME: this.form.lastName,
          TALENT_PROFILE_PICTURE: this.form.profile,
          TALENT_WORK_TIMEZONE: this.form.timezone,
          LOCATION: this.form.location,
        },
        country: this.form.country,
      }
      API_SERVICE.auth.updateUser(data)
        .then((res) => {
          this.$track.sendEvent('ProfileEdit', {section: 'personal-info'})
          this.setUser(res);
          // @ts-ignore
          this.$rs.toast({
            icon: Icons.CHECKMARK_CHECKED,
            title: 'Changes saved',
            body: 'Your profile has been updated'
          })
        })
        .catch((err) => {
          this.error = err.message;
        })
        .finally(() => {
          this.sending.form = false;
        })
    }
  }

  fillForm(user: IApiTalent){
    this.form.firstName = user.properties.TALENT_NAME || '';
    this.form.lastName = user.properties.TALENT_LAST_NAME || '';
    this.form.location = user.properties.LOCATION || '';
    this.form.country = user.country || '';
    this.form.profile = user.properties.TALENT_PROFILE_PICTURE || null;
    this.form.timezone = user.properties.TALENT_WORK_TIMEZONE || '';
  }

  get getLocationSuggestions() {
    const self = this;
    return debounce(async input => {
      self.locationSuggestions = [];

      if (!input) return;
      if (!self.service) {
        self.service = await new (window as any).google.maps.places.AutocompleteService();
      }

      this.service.getPlacePredictions(
        {
          input,
          types: ['(cities)'],
          language: 'en',
        },
        (suggestions: any[]) => {
          self.locationSuggestions = suggestions || [];
        }
      );
    }, 500)
  }

  selectLocation(location: any) {
    this.form.country = location.terms[location.terms.length - 1].value;
    this.form.location = location.description;
    this.locationSuggestions = [];
    this.form.timezone = `UTC${DATE_SERVICE.getUTCOffset()}`
  }

  selectLocationEnter(){
    if(this.locationSuggestions.length > 0){
      if(this.locationSuggestionsIndex >= 0 && this.locationSuggestionsIndex < this.locationSuggestions.length){
        this.selectLocation(this.locationSuggestions[this.locationSuggestionsIndex]);
      }
      else {
        this.selectLocation(this.locationSuggestions[0]);
      }
    }
  }

  closeAutocomplete(){
    setTimeout(() => {
      this.locationSuggestions = [];
    }, 200)
  }

  async mounted() {
    this.codetables = await API_SERVICE.codetable.getCodetables(Codetables.TIMEZONES);
    this.service = await new (window as any).google.maps.places.AutocompleteService();
    if(this.user){
      this.fillForm(this.user);
    }
  }
}
