














import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'rs-textarea',
})
export default class RSInput extends Vue {
  @Prop({required: true}) value: any;
  @Prop({required: false}) error?: boolean;

  valueProxy = '';

  get listeners() {
    delete this.$listeners.input;
    return this.$listeners;
  }

  updateValue() {
    this.valueProxy = (this.$refs.textarea as HTMLInputElement).value;
    this.$emit('input', this.valueProxy);
  }

}
