























































































































































































































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import {IApiTalent} from '@/shared/models/IApiTalent';
import RsButton from '@/shared/components/buttons/button.vue';
import OnboardingModule from '@/modules/onboarding'
import {Watch} from 'vue-property-decorator';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import {API_SERVICE} from '@/shared/api/api.service';
import {
  CodetableResult,
  Codetables,
  Skill,
  SkillExperienceLevel,
  TalentEngineeringExperience
} from '@/shared/api/modules/codetable.api.service';
import {DeveloperRoleInfo, DeveloperRoleType, rolesInfo} from '@/shared/constants/ROLES';
import RsInput from '@/shared/components/fields/input.vue';
import RsSelect from '@/shared/components/fields/select.vue';
import RsCheckbox from '@/shared/components/fields/checkbox.vue';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import {IApiTalentRoleExperience} from '@/shared/models/IApiTalentRoleExperience';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {Icons} from '@/shared/components/icon/icons';
import {formatExperienceLabel} from '@/shared/helpers/format';

interface FormSkills {
  role: string[],
  years: Record<string, number>;
  tech: string[];
  mainSkills: { skill: string, experience: SkillExperienceLevel }[];
  supportSkills: { skill: string, experience: SkillExperienceLevel }[];
  isManager: boolean;
  managerYears: string;
  managerTeamSize: string;
}

interface TempFormSkils {
  main: { skill: string, experience: SkillExperienceLevel | '' }
  support: { skill: string, experience: SkillExperienceLevel | '' }
  search: {
    main: string[],
    mainSuggestion: number,
    support: string[],
    supportSuggestion: number,
    tech: TalentEngineeringExperience[],
    techSuggestion: number,
  },
  tech: {
    input: string;
    added: string[];
  },
}

@Component({
  name: 'rs-form-profile-edit-skills',
  components: {RsCheckbox, RsSelect, RsInput, RsButton, RsIcon, RsCheckboxCard},

  validations() {
    const self: any = this;
    return {
      form: {
        role: {
          minimumLength(val) {
            if (self.user.properties.TALENT_ROLE_EXPERIENCES && self.user.properties.TALENT_ROLE_EXPERIENCES.length > 0) {
              return val && val.length > 0;
            }
            return true;
          }
        },
      }
    }
  }
})
export default class FormProfileEditSkills extends Vue {
  @Action(OnboardingActions.SET_USER, {namespace: OnboardingModule.namespace}) setUser: (user: IApiTalent) => void;
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  formatExperienceLabel = formatExperienceLabel;

  Icons = Icons;

  rolesInfo: Record<DeveloperRoleType, DeveloperRoleInfo> = rolesInfo;

  form: FormSkills = {
    role: [],
    years: {},
    tech: [],
    mainSkills: [],
    supportSkills: [],
    isManager: false,
    managerYears: '',
    managerTeamSize: ''
  }

  dirty: Record<string, boolean> = {};
  yearsValid = true;

  tempform: TempFormSkils = {
    main: {
      skill: '',
      experience: '',
    },
    support: {
      skill: '',
      experience: '',
    },
    search: {
      main: [],
      mainSuggestion: -1,
      support: [],
      supportSuggestion: -1,
      tech: [],
      techSuggestion: -1
    },
    tech: {
      input: '',
      added: []
    }
  }

  pagination = {
    tech: false,
  }

  sending = {
    form: false,
  }

  codetables: CodetableResult = {}

  error = '';

  numberMask = '';


  @Watch('user')
  onUserChange(user: IApiTalent) {
    this.fillForm(user);
  }

  checkYears() {
    for (let role of this.form.role) {
      const year = this.form.years[role];
      if (year < 1 || year > 20) {
        this.yearsValid = false;
        return false;
      }
    }
    this.yearsValid = true;
    return true;
  }

  focusManagerYears(value: boolean) {
    if (value) {
      this.$nextTick(() => {
        const input = (this.$refs.managerYears as any).$el.querySelector('input');
        input.focus();
      })
    }
  }

  paginatedExperiences() {
    if(!this.pagination.tech){
      const first = this.filteredExperiences()?.slice(0, 15) || [];
      const restActive = this.filteredExperiences()?.slice(15, this.filteredExperiences()?.length).filter((exp) => this.form.tech.includes(exp.name)) || [];
      return [...first, ...restActive]
    }
    return this.filteredExperiences()
  }


  filteredExperiences() {
    if(this.form.role.length > 0) {
      const byRole = (this.codetables.talent_engineering_experiences?.filter((exp) => {
        return exp.roles.some(role => this.form.role.includes(role))
      }) || [])
      const rest = (this.codetables.talent_engineering_experiences?.filter((exp) => {
        return !exp.roles.some(role => this.form.role.includes(role))
      }) || [])
      return [
        ...byRole,
        ...rest
      ]
    }
    else {
      return this.codetables.talent_engineering_experiences
    }
  }

  addRole(roles: string[] | undefined, role: string) {
    if (roles && roles.length && roles.includes(role)) {
      this.$nextTick(() => {
        const input = (this.$refs[role] as any[])[0].$el.querySelector('input');
        input.focus();
      })
      this.dirty[role] = false;
    }
  }

  removeRole(role: string) {
    this.form.role = this.form.role.filter((r: string) => r !== role);
  }

  addTechSkill(input?: string) {
    if(input){
      if (!this.form.tech.includes(input) && this.tempform.tech.added.length < 3) {
        const tech = input.trim();
        this.form.tech.push(tech);
        this.tempform.tech.input = '';
        this.tempform.search.techSuggestion = -1;
        this.tempform.search.tech = [];
      }
    }
    else{
      if (this.tempform.tech.input && !this.form.tech.includes(this.tempform.tech.input) && this.tempform.tech.added.length < 3) {
        const tech = this.tempform.tech.input.trim();
        this.tempform.tech.added.push(tech);
        this.form.tech.push(tech);
        this.tempform.tech.input = '';
        this.tempform.search.techSuggestion = -1;
        this.tempform.search.tech = [];
      }
    }

  }

  addTechSkillEnter() {
    if (this.tempform.search.techSuggestion >= 0 && this.tempform.search.tech.length > 0) {
      if (this.tempform.search.techSuggestion >= 0 && this.tempform.search.techSuggestion < this.tempform.search.tech.length) {
        this.addTechSkill(this.tempform.search.tech[this.tempform.search.techSuggestion].name)
      } else {
        this.addTechSkill(this.tempform.search.tech[0].name)
      }
    } else {
      this.addTechSkill();
    }
    this.tempform.search.techSuggestion = -1;
  }

  addMainSkill() {
    if (this.form.mainSkills.length >= 4) {
      return false;
    }
    if (this.tempform.main.skill && this.tempform.main.experience) {
      this.form.mainSkills = [...this.form.mainSkills, {
        skill: this.tempform.main.skill.trim(),
        experience: this.tempform.main.experience
      }];
      this.tempform.main.skill = '';
      this.tempform.main.experience = '';
    }
  }

  addMainSkillEnter() {
    if (this.tempform.search.main.length > 0) {
      if (this.tempform.search.mainSuggestion >= 0 && this.tempform.search.mainSuggestion < this.tempform.search.main.length) {
        this.addMainSuggestion(this.tempform.search.main[this.tempform.search.mainSuggestion])
      } else {
        this.addMainSuggestion(this.tempform.search.main[0])
      }
    } else {
      this.addMainSkill();
    }
    this.tempform.search.mainSuggestion = -1;
  }

  removeMainSkill(index: number) {
    this.form.mainSkills.splice(index, 1);
  }


  addSupportSkill() {
    if (this.form.supportSkills.length >= 8) {
      return false;
    }
    this.tempform.search.support = [];
    if (this.tempform.support.skill && this.tempform.support.experience) {
      this.form.supportSkills = [...this.form.supportSkills, {
        skill: this.tempform.support.skill.trim(),
        experience: this.tempform.support.experience
      }];
      this.tempform.support.skill = '';
      this.tempform.support.experience = '';
    }
  }

  addSupportSkillEnter() {
    if (this.tempform.search.support.length > 0) {
      if (this.tempform.search.supportSuggestion >= 0 && this.tempform.search.supportSuggestion < this.tempform.search.support.length) {
        this.addSupportSuggestion(this.tempform.search.support[this.tempform.search.supportSuggestion])
      } else {
        this.addSupportSuggestion(this.tempform.search.support[0])
      }
    } else {
      this.addSupportSkill();
    }
    this.tempform.search.supportSuggestion = -1;
  }

  removeSupportSkill(index: number) {
    this.form.supportSkills.splice(index, 1);
  }

  searchMainSkills() {
    const mainSkills = this.form.mainSkills.map((mainSkill) => mainSkill.skill);
    this.tempform.search.main = ((this.codetables.skills || []) as Skill[]).filter((skill: Skill) => {
      if (mainSkills.includes(skill.name)) {
        return false;
      }
      return skill.name.toLowerCase().includes(this.tempform.main.skill.toLowerCase());
    }).map(skill => skill.name).slice(0, 5);
  }

  searchSupportSkills() {
    const supportSkills = this.form.supportSkills.map((supportSkill) => supportSkill.skill);
    this.tempform.search.support = ((this.codetables.skills || []) as Skill[]).filter((skill: Skill) => {
      if (supportSkills.includes(skill.name)) {
        return false;
      }
      return skill.name.toLowerCase().includes(this.tempform.support.skill.toLowerCase());
    }).map(skill => skill.name).slice(0, 5);
  }

  searchTechSkills() {
    const techSkills = this.form.tech;
    if(this.tempform.tech.input?.length){
      this.tempform.search.tech = (((this.codetables.talent_engineering_experiences || []) as TalentEngineeringExperience[]).filter((experience: TalentEngineeringExperience) => {
        if (techSkills.includes(experience.name)) {
          return false;
        }
        return experience.displayName.toLowerCase().includes(this.tempform.tech.input.toLowerCase());
      }) || []).slice(0, 5)
    } else {
      this.tempform.search.tech = [];
    }
  }

  addMainSuggestion(suggestion: string) {
    this.tempform.main.skill = suggestion;
    this.tempform.search.main = [];
    this.addMainSkill();
  }

  addSupportSuggestion(suggestion: string) {
    this.tempform.support.skill = suggestion;
    this.tempform.search.support = [];
    this.addSupportSkill();
  }

  submit() {
    this.error = '';
    this.sending.form = true;
    const data: IApiTalentUpdateRequest = {
      properties: {
        TALENT_TECH_EXPERIENCES: this.form.tech.length ? [...this.form.tech] : undefined,
        TALENT_ROLE_EXPERIENCES: this.form.role.filter((r) =>
          this.form.years[r] && this.form.years[r] >= 1 && this.form.years[r] <= 20).length ? this.form.role
            .filter((r) => this.form.years[r] && this.form.years[r] >= 1 && this.form.years[r] <= 20)
            .map((r) => {
              return {
                TALENT_ROLE: r,
                TALENT_ROLE_YEARS: Number(this.form.years[r]),
              };
            }) : undefined,
        TALENT_MAIN_SKILLS: this.form.mainSkills.length > 0 ? this.form.mainSkills.map((s) => {
          return {
            TALENT_SKILL_NAME: s.skill,
            TALENT_SKILL_EXPERIENCE: s.experience.name,
          };
        }) : undefined,
        TALENT_SUPPORT_SKILLS: this.form.supportSkills.length > 0 ? this.form.supportSkills.map((s) => {
          return {
            TALENT_SKILL_NAME: s.skill,
            TALENT_SKILL_EXPERIENCE: s.experience.name,
          };
        }) : undefined,
        MANAGEMENT: this.form.isManager
          ? {
            MANAGEMENT_YEARS: parseInt(this.form.managerYears),
            MANAGEMENT_TEAM_SIZE: this.form.managerTeamSize as string,
          }
          : undefined,
      },
    }
    API_SERVICE.auth.updateUser(data)
      .then((res) => {
        this.$track.sendEvent('ProfileEdit', {section: 'skills'})
        this.setUser(res);
        // @ts-ignore
        this.$rs.toast({
          icon: Icons.CHECKMARK_CHECKED,
          title: 'Changes saved',
          body: 'Your profile has been updated'
        })
      })
      .catch((err) => {
        this.error = err.message;
      })
      .finally(() => {
        this.sending.form = false;
      })
  }

  fillForm(user: IApiTalent) {
    this.form.mainSkills = user.properties.TALENT_MAIN_SKILLS?.map((skill) => {
      const expLvl = (this.codetables.skill_experience_levels as SkillExperienceLevel[])
        .find((exp) => skill.TALENT_SKILL_EXPERIENCE == exp.name);
      return {
        skill: skill.TALENT_SKILL_NAME,
        experience: expLvl || (this.codetables.skill_experience_levels as SkillExperienceLevel[])[0]
      }
    }) || [];
    this.form.supportSkills = user.properties.TALENT_SUPPORT_SKILLS?.map((skill) => {
      const expLvl = (this.codetables.skill_experience_levels as SkillExperienceLevel[])
        .find((exp) => skill.TALENT_SKILL_EXPERIENCE == exp.name);
      return {
        skill: skill.TALENT_SKILL_NAME,
        experience: expLvl || (this.codetables.skill_experience_levels as SkillExperienceLevel[])[0]
      }
    }) || [];
    if (user.properties.TALENT_TECH_EXPERIENCES) {
      const existing = (this.codetables.talent_engineering_experiences as TalentEngineeringExperience[])
        .map((e: TalentEngineeringExperience) => e.name);
      this.form.tech = user.properties.TALENT_TECH_EXPERIENCES;
      this.tempform.tech.added = user.properties.TALENT_TECH_EXPERIENCES.filter((e) => !existing.includes(e));
      if(this.form.tech.length > 0){
        this.pagination.tech = true;
      }
    }
    if (user.properties.TALENT_ROLE_EXPERIENCES) {
      this.form.role = user.properties.TALENT_ROLE_EXPERIENCES.map((role) => role.TALENT_ROLE);

      this.form.years = (user.properties.TALENT_ROLE_EXPERIENCES as IApiTalentRoleExperience[])
        .reduce((a: Record<string, number>, b) => {
          a[b.TALENT_ROLE] = b.TALENT_ROLE_YEARS;
          return a;
        }, {});
    }

    this.$nextTick(() => {
      this.numberMask = createNumberMask({
        prefix: ''
      })
    })

    if (user.properties.MANAGEMENT) {
      this.form.isManager = true;
      this.form.managerYears = user.properties.MANAGEMENT.MANAGEMENT_YEARS.toString()
      this.form.managerTeamSize = user.properties.MANAGEMENT.MANAGEMENT_TEAM_SIZE
    }
  }


  async mounted() {
    this.codetables = await API_SERVICE.codetable.getCodetables(
      Codetables.DEVELOPER_ROLES,
      Codetables.SKILLS,
      Codetables.TALENT_ENGINEERING_EXPERIENCES,
      Codetables.SKILL_EXPERIENCE_LEVELS,
      Codetables.MANAGEMENT_TEAM_SIZES
    );
    if (this.user) {
      this.fillForm(this.user);
    }
  }

  closeMainAutocomplete(){
    setTimeout(() => {
      this.tempform.search.main = [];
    }, 200)
  }

  closeSupportAutocomplete(){
    setTimeout(() => {
      this.tempform.search.support = [];
    }, 200)
  }

  closeTechAutocomplete(){
    setTimeout(() => {
      this.tempform.search.tech = [];
    }, 200)
  }
}
