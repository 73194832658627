

































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsInput from '@/shared/components/fields/input.vue';
import {required} from 'vuelidate/lib/validators';
import RsCheckbox from '@/shared/components/fields/checkbox.vue';
import RsTextarea from '@/shared/components/fields/textarea.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {Prop} from 'vue-property-decorator';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RsEditor from '@/shared/components/fields/editor.vue';

interface EducationForm {
  title: string;
  companyName: string;
  currentOccupation: boolean;
  startDate: string;
  endDate: string;
  graduationFailed: boolean;
  achievements: string;
}

export interface EducationModel {
  title: string;
  companyName: string;
  currentOccupation: boolean;
  startDate: string;
  endDate?: string;
  graduationFailed: boolean;
  achievements?: string;
}

@Component({
  name: 'rs-form-profile-edit-work-education',
  components: {RsEditor, RsCheckbox, RsInput, RsTextarea, RsButton},
  validations() {
    const self: any = this;
    return {
      form: {
        title: {
          required
        },
        companyName: {
          required
        },
        startDate: {
          required,
          valid: (val) => {
            if(val){
              return val && val.length === 5 && val.split('/')[0] < 13;
            }
            return false;
          },
          inPast: (val) => {
            if(val && val.length === 5){
              dayjs.extend(customParseFormat);
              const date = dayjs(val, 'MM/YY');
              return date.isBefore(dayjs());
            }
            return false;
          }
        },
        endDate: {
          isRequired: (val) => self.form.currentOccupation ? true : !!val,
          valid: (val) => {
            if(val && !self.form.currentOccupation){
              return val && val.length === 5 && val.split('/')[0] < 13;
            }
            return true;
          },
          inPast: (val) => {
            if(val && !self.form.currentOccupation && val.length === 5){
              dayjs.extend(customParseFormat);
              const date = dayjs(val, 'MM/YY');
              return date.isBefore(dayjs());
            }
            return true;
          }
        }
      }
    }
  }
})
export default class FormProfileEditWorkEducation extends Vue {
  @Prop({required: false}) data: EducationModel;

  form: EducationForm = {
    title: '',
    companyName: '',
    currentOccupation: false,
    startDate: '',
    endDate: '',
    graduationFailed: false,
    achievements: ''
  }

  submit() {
    this.$emit('save', this.form);
    this.reset();
  }

  reset(){
    this.form = {
      title: '',
      companyName: '',
      currentOccupation: false,
      startDate: '',
      endDate: '',
      graduationFailed: false,
      achievements: ''
    }
    this.$v.$reset();
  }

  close(){
    this.$emit('close');
  }


  mounted(){
    if(this.data){
      this.form = {
        title: this.data.title || '',
        companyName: this.data.companyName || '',
        currentOccupation: this.data.currentOccupation || false,
        startDate: this.data.startDate || '',
        endDate: this.data.endDate || '',
        graduationFailed: this.data.graduationFailed || false,
        achievements: this.data.achievements || ''
      };
    }
  }
}
