

























































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsInput from '@/shared/components/fields/input.vue';
import {required} from 'vuelidate/lib/validators';
import RsCheckbox from '@/shared/components/fields/checkbox.vue';
import {API_SERVICE} from '@/shared/api/api.service';
import {
  CodetableResult,
  Codetables,
  TalentEngineeringExperience
} from '@/shared/api/modules/codetable.api.service';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import RsTextarea from '@/shared/components/fields/textarea.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {Prop} from 'vue-property-decorator';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import RsEditor from '@/shared/components/fields/editor.vue';

interface ExperienceForm {
  title: string;
  companyName: string;
  currentOccupation: boolean;
  startDate: string;
  endDate: string;
  experiences: string[];
  description: string;
}

export interface ExperienceModel {
  title: string;
  companyName: string;
  currentOccupation: boolean;
  startDate: string;
  endDate?: string;
  experiences?: TalentEngineeringExperience[];
  description?: string;
}

interface TempFormExperience {
  tech: {
    input: string;
    added: string[];
  },
}

@Component({
  name: 'rs-form-profile-edit-work-experience',
  components: {RsEditor, RsButton, RsTextarea, RsCheckboxCard, RsCheckbox, RsInput},
  validations() {
    const self: any = this;
    return {
      form: {
        title: {
          required
        },
        companyName: {
          required
        },
        startDate: {
          required,
          valid: (val) => {
            if(val){
              return val && val.length === 5 && val.split('/')[0] < 13 && val.split('/')[0] > 0;
            }
            return false;
          },
          inPast: (val) => {
            if(val && val.length === 5){
              dayjs.extend(customParseFormat);
              const date = dayjs(val, 'MM/YY');
              return date.isBefore(dayjs());
            }
            return false;
          }
        },
        endDate: {
          isRequired: (val) => self.form.currentOccupation ? true : !!val,
          valid: (val) => {
            if(val && !self.form.currentOccupation){
              return val && val.length === 5 && val.split('/')[0] < 13 && val.split('/')[0] > 0;
            }
            return true;
          },
          inPast: (val) => {
            if(val && !self.form.currentOccupation && val.length === 5){
              dayjs.extend(customParseFormat);
              const date = dayjs(val, 'MM/YY');
              return date.isBefore(dayjs());
            }
            return true;
          }
        }
      }
    }
  }
})
export default class FormProfileEditWorkExperience extends Vue {
  @Prop({required: false}) data: ExperienceModel;

  form: ExperienceForm = {
    title: '',
    companyName: '',
    currentOccupation: false,
    startDate: '',
    endDate: '',
    experiences: [],
    description: ''
  }

  tempform: TempFormExperience = {
    tech: {
      input: '',
      added: []
    }
  }

  codetables: CodetableResult = {}

  addTechSkill(){
    if(this.tempform.tech.input && !this.form.experiences.includes(this.tempform.tech.input)){
      this.tempform.tech.added.push(this.tempform.tech.input);
      this.form.experiences.push(this.tempform.tech.input);
      this.tempform.tech.input = '';
    }
  }

  submit() {
    const data: ExperienceModel = {
      ...this.form,
      experiences: [...(this.codetables.talent_engineering_experiences as TalentEngineeringExperience[] || [])
        .filter((exp: TalentEngineeringExperience) => this.form.experiences.includes(exp.name)),
      ...(this.form.experiences.filter(
        (exp) => !(this.codetables.talent_engineering_experiences as TalentEngineeringExperience[])
          ?.map((exp) => exp.name)?.includes(exp)) as string[]).map((exp): TalentEngineeringExperience => ({
        displayName: exp,
        name: exp,
        visible: true,
        roles: []
      })) as TalentEngineeringExperience[]
      ],
    }
    this.$emit('save', data);
    this.reset();
  }

  reset(){
    this.form = {
      title: '',
      companyName: '',
      currentOccupation: false,
      startDate: '',
      endDate: '',
      experiences: [],
      description: ''
    };
    this.tempform = {
      tech: {
        input: '',
        added: []
      }
    }
    this.$v.$reset();
  }

  close() {
    this.$emit('close');
  }

  async mounted() {
    this.codetables = await API_SERVICE.codetable.getCodetables(
      Codetables.TALENT_ENGINEERING_EXPERIENCES,
    );
    if (this.data) {
      this.form = {
        title: this.data.title || '',
        companyName: this.data.companyName || '',
        currentOccupation: this.data.currentOccupation || false,
        startDate: this.data.startDate || '',
        endDate: this.data.endDate || '',
        description: this.data.description || '',
        experiences: this.data.experiences?.map(exp => exp.name) || []
      };
      const existing = (this.codetables.talent_engineering_experiences as TalentEngineeringExperience[])
        .map((e: TalentEngineeringExperience) => e.name);
      this.tempform.tech.added = this.data.experiences?.filter((e) => !existing.includes(e.name))
        .map((e: TalentEngineeringExperience) => e.name) || [];
    }
  }
}
