













import Component from 'vue-class-component';
import Vue from 'vue';
import RsFormProfileEditSkills from '@/modules/dashboard/modules/profile/components/forms/edit/skills.vue';

@Component({
  name: 'rs-profile-edit-skills',
  components: {RsFormProfileEditSkills}
})
export default class ProfileEditSkills extends Vue {}
