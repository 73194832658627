





























































import Component from 'vue-class-component';
import Vue from 'vue';
import RsInput from '@/shared/components/fields/input.vue';
import {required} from 'vuelidate/lib/validators';
import RsButton from '@/shared/components/buttons/button.vue';
import {Prop} from 'vue-property-decorator';

interface CertificateForm {
  title: string;
  date: string;
}

export interface CertificateModel {
  title: string;
  date: string;
}

@Component({
  name: 'rs-form-profile-edit-work-certificate',
  components: {RsButton, RsInput},
  validations() {
    return {
      form: {
        title: {
          required
        },
        date: {
          required,
          valid: (val) => {
            if(val){
              return val && val.length === 5 && val.split('/')[0] < 13;
            }
            return false;
          }
        },
      }
    }
  }
})
export default class FormProfileEditWorkExperience extends Vue {
  @Prop({required: false}) data: CertificateModel | null;

  form: CertificateForm = {
    title: '',
    date: '',
  }

  submit() {
    this.$emit('save', this.form);
    this.reset();
  }

  reset(){
    this.form = {
      title: '',
      date: '',
    }
    this.$v.$reset();
  }

  close(){
    this.$emit('close');
  }

  mounted(){
    if(this.data){
      this.form = {
        title: this.data.title || '',
        date: this.data.date || '',
      }
    }
  }
}
