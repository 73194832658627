




















































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsInput from '@/shared/components/fields/input.vue';
import {required, url} from 'vuelidate/lib/validators';
import {API_SERVICE} from '@/shared/api/api.service';
import {CodetableResult, Codetables, TalentEngineeringExperience} from '@/shared/api/modules/codetable.api.service';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import RsTextarea from '@/shared/components/fields/textarea.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {Prop} from 'vue-property-decorator';
import RsEditor from '@/shared/components/fields/editor.vue';

interface ProjectForm {
  title: string;
  url: string;
  date: string;
  experiences: string[],
  description: string;
}

export interface ProjectModel {
  title: string;
  url: string;
  date: string;
  experiences?: TalentEngineeringExperience[],
  description?: string;
}

interface TempFormProject {
  tech: {
    input: string;
    added: string[];
  },
}

@Component({
  name: 'rs-form-profile-edit-work-project',
  components: {RsEditor, RsButton, RsTextarea, RsCheckboxCard, RsInput},
  validations() {
    return {
      form: {
        title: {
          required
        },
        url: {
          required,
          url
        },
        date: {
          required,
          valid: (val) => {
            if(val){
              return val && val.length === 5 && val.split('/')[0] < 13;
            }
            return false;
          }
        },
      }
    }
  }
})
export default class FormProfileEditWorkProject extends Vue {
  @Prop({required: false}) data: ProjectModel;

  form: ProjectForm = {
    title: '',
    url: '',
    date: '',
    experiences: [],
    description: ''
  }

  tempform: TempFormProject = {
    tech: {
      input: '',
      added: []
    }
  }

  codetables: CodetableResult = {}

  addTechSkill(){
    if(this.tempform.tech.input && !this.form.experiences.includes(this.tempform.tech.input)){
      this.tempform.tech.added.push(this.tempform.tech.input);
      this.form.experiences.push(this.tempform.tech.input);
      this.tempform.tech.input = '';
    }
  }

  submit() {
    const data: ProjectModel = {
      ...this.form,
      experiences: [...(this.codetables.talent_engineering_experiences as TalentEngineeringExperience[] || [])
        .filter((exp: TalentEngineeringExperience) => this.form.experiences.includes(exp.name)),
      ...(this.form.experiences.filter((exp) =>
        !(this.codetables.talent_engineering_experiences as TalentEngineeringExperience[])?.map((exp) => exp.name)
          ?.includes(exp)) as string[]).map((exp: string) => ({
        displayName: exp,
        name: exp,
        visible: true,
      })) as TalentEngineeringExperience[]
      ],
    }
    this.$emit('save', data);
    this.reset();
  }

  reset(){
    this.form = {
      title: '',
      url: '',
      date: '',
      experiences: [],
      description: ''
    };

    this.tempform = {
      tech: {
        input: '',
        added: []
      }
    };

    this.$v.$reset();
  }

  close() {
    this.$emit('close');
  }

  async mounted() {
    this.codetables = await API_SERVICE.codetable.getCodetables(
      Codetables.TALENT_ENGINEERING_EXPERIENCES,
    );

    if (this.data) {
      this.form = {
        title: this.data.title || '',
        url: this.data.url || '',
        date: this.data.date || '',
        experiences: this.data.experiences?.map(exp => exp.name) || [],
        description: this.data.description || ''
      };
      const existing = (this.codetables.talent_engineering_experiences as TalentEngineeringExperience[])
        .map((e: TalentEngineeringExperience) => e.name);
      this.tempform.tech.added = this.data.experiences?.filter((e) => !existing.includes(e.name))
        .map((e: TalentEngineeringExperience) => e.name) || [];
    }
  }
}
