













import Component from 'vue-class-component';
import Vue from 'vue';
import RsFormProfileEditPersonalInfo from '@/modules/dashboard/modules/profile/components/forms/edit/personal-info.vue';

@Component({
  name: 'rs-profile-edit-personal-info',
  components: {RsFormProfileEditPersonalInfo}
})
export default class ProfileEditPersonalInfo extends Vue {}
