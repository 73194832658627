










import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';

@Component({
  name: 'rs-editor',
  components: {
    EditorContent,
  },
})
export default class RSEditor extends Vue {
  @Prop({ required: true }) value: string;

  @Prop({
    required: false,
    default: '',
  }) placeholder: string;

  @Prop({ required: false }) error?: boolean;

  @Prop({ required: false }) minHeight?: string;

  editor: Editor | null = null;

  valueProxy = '';


  @Watch('value')
  onValueChange(val: string) {
    if (this.valueProxy === val && val.length > 0) {
      return;
    }
    this.valueProxy = val;
    if (this.editor) {
      this.editor.commands.setContent(val, false);
    }
  }

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      ],
      onUpdate: () => {
        if (this.editor) {
          this.valueProxy = this.editor.getHTML();
          this.$emit('input', this.valueProxy);
        }
      },
      onFocus: () => {
        this.$emit('focus');
      },
      onBlur: () => {
        this.$emit('blur');
      },
    });
  }

  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
