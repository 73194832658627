













import Component from 'vue-class-component';
import Vue from 'vue';
import RsFormProfileEditPreferences
  from '@/modules/dashboard/modules/profile/components/forms/edit/preferences.vue';

@Component({
  name: 'rs-profile-edit-preferences',
  components: {RsFormProfileEditPreferences},
})
export default class ProfileEditPreferences extends Vue {}
